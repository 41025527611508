/**
 * Worksheet class
 * @private
 */
export class Cell {
}
/**
 * Cells class
 * @private
 */
export class Cells extends Array {
    constructor() {
        super(...arguments);
        this.add = (cell) => {
            let inserted = false;
            let count = 0;
            for (let c of this) {
                if (c.index === cell.index) {
                    this[count] = cell;
                    inserted = true;
                }
                count++;
            }
            if (!inserted) {
                this.push(cell);
            }
        };
    }
}
