var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { extend } from './util';
import { Property, Complex, NotifyPropertyChanges, Event } from './notify-property-change';
import { Browser } from './browser';
import { Base } from './base';
import { ChildProperty } from './child-property';
import { EventHandler } from './event-handler';
/**
 * SwipeSettings is a framework module that provides support to handle swipe event like swipe up, swipe right, etc..,
 */
export class SwipeSettings extends ChildProperty {
}
__decorate([
    Property(50)
], SwipeSettings.prototype, "swipeThresholdDistance", void 0);
const swipeRegex = /(Up|Down)/;
/**
 * Touch class provides support to handle the touch event like tap, double tap, tap hold, etc..,
 * ```typescript
 *    let node: HTMLElement;
 * let touchObj: Touch = new Touch({
 *    element: node,
 *    tap: function (e) {
 *        // tap handler function code
 *    }
 *    tapHold: function (e) {
 *        // tap hold handler function code
 *    }
 *    scroll: function (e) {
 *        // scroll handler function code
 *    }
 *    swipe: function (e) {
 *        // swipe handler function code
 *    }
 * });
 * ```
 */
let Touch = class Touch extends Base {
    /* End-Properties */
    constructor(element, options) {
        super(options, element);
        this.touchAction = true;
        this.tapCount = 0;
        /**
         *
         * @param {MouseEventArgs | TouchEventArgs} evt ?
         * @returns {void} ?
         */
        this.startEvent = (evt) => {
            if (this.touchAction === true) {
                const point = this.updateChangeTouches(evt);
                if (evt.changedTouches !== undefined) {
                    this.touchAction = false;
                }
                this.isTouchMoved = false;
                this.movedDirection = '';
                this.startPoint = this.lastMovedPoint = { clientX: point.clientX, clientY: point.clientY };
                this.startEventData = point;
                this.hScrollLocked = this.vScrollLocked = false;
                this.tStampStart = Date.now();
                this.timeOutTapHold = setTimeout(() => { this.tapHoldEvent(evt); }, this.tapHoldThreshold);
                EventHandler.add(this.element, Browser.touchMoveEvent, this.moveEvent, this);
                EventHandler.add(this.element, Browser.touchEndEvent, this.endEvent, this);
                EventHandler.add(this.element, Browser.touchCancelEvent, this.cancelEvent, this);
            }
        };
        /**
         *
         * @param {MouseEventArgs | TouchEventArgs} evt ?
         * @returns {void} ?
         */
        this.moveEvent = (evt) => {
            const point = this.updateChangeTouches(evt);
            this.movedPoint = point;
            this.isTouchMoved = !(point.clientX === this.startPoint.clientX && point.clientY === this.startPoint.clientY);
            let eScrollArgs = {};
            if (this.isTouchMoved) {
                clearTimeout(this.timeOutTapHold);
                this.calcScrollPoints(evt);
                const scrollArg = {
                    startEvents: this.startEventData,
                    originalEvent: evt, startX: this.startPoint.clientX,
                    startY: this.startPoint.clientY, distanceX: this.distanceX,
                    distanceY: this.distanceY, scrollDirection: this.scrollDirection,
                    velocity: this.getVelocity(point)
                };
                eScrollArgs = extend(eScrollArgs, {}, scrollArg);
                this.trigger('scroll', eScrollArgs);
                this.lastMovedPoint = { clientX: point.clientX, clientY: point.clientY };
            }
        };
        /**
         *
         * @param {MouseEventArgs | TouchEventArgs} evt ?
         * @returns {void} ?
         */
        this.cancelEvent = (evt) => {
            clearTimeout(this.timeOutTapHold);
            clearTimeout(this.timeOutTap);
            this.tapCount = 0;
            this.swipeFn(evt);
            EventHandler.remove(this.element, Browser.touchCancelEvent, this.cancelEvent);
        };
        /**
         *
         * @param {MouseEventArgs | TouchEventArgs} evt ?
         * @returns {void} ?
         */
        this.endEvent = (evt) => {
            this.swipeFn(evt);
            if (!this.isTouchMoved) {
                if (typeof this.tap === 'function') {
                    this.trigger('tap', { originalEvent: evt, tapCount: ++this.tapCount });
                    this.timeOutTap = setTimeout(() => {
                        this.tapCount = 0;
                    }, this.tapThreshold);
                }
            }
            this.modeclear();
        };
        /**
         *
         * @param {MouseEventArgs | TouchEventArgs} evt ?
         * @returns {void} ?
         */
        this.swipeFn = (evt) => {
            clearTimeout(this.timeOutTapHold);
            clearTimeout(this.timeOutTap);
            const point = this.updateChangeTouches(evt);
            let diffX = point.clientX - this.startPoint.clientX;
            let diffY = point.clientY - this.startPoint.clientY;
            diffX = Math.floor(diffX < 0 ? -1 * diffX : diffX);
            diffY = Math.floor(diffY < 0 ? -1 * diffY : diffX);
            this.isTouchMoved = diffX > 1 || diffY > 1;
            const isFirefox = (/Firefox/).test(Browser.userAgent);
            if (isFirefox && point.clientX === 0 && point.clientY === 0 && evt.type === 'mouseup') {
                this.isTouchMoved = false;
            }
            this.endPoint = point;
            this.calcPoints(evt);
            const swipeArgs = {
                originalEvent: evt,
                startEvents: this.startEventData,
                startX: this.startPoint.clientX,
                startY: this.startPoint.clientY,
                distanceX: this.distanceX, distanceY: this.distanceY, swipeDirection: this.movedDirection,
                velocity: this.getVelocity(point)
            };
            if (this.isTouchMoved) {
                let eSwipeArgs;
                const tDistance = this.swipeSettings.swipeThresholdDistance;
                // eslint-disable-next-line
                eSwipeArgs = extend(eSwipeArgs, this.defaultArgs, swipeArgs);
                let canTrigger = false;
                const ele = this.element;
                const scrollBool = this.isScrollable(ele);
                const moved = swipeRegex.test(this.movedDirection);
                if ((tDistance < this.distanceX && !moved) || (tDistance < this.distanceY && moved)) {
                    if (!scrollBool) {
                        canTrigger = true;
                    }
                    else {
                        canTrigger = this.checkSwipe(ele, moved);
                    }
                }
                if (canTrigger) {
                    this.trigger('swipe', eSwipeArgs);
                }
            }
            this.modeclear();
        };
        this.modeclear = () => {
            this.modeClear = setTimeout(() => {
                this.touchAction = true;
            }, (typeof this.tap !== 'function' ? 0 : 20));
            this.lastTapTime = new Date().getTime();
            EventHandler.remove(this.element, Browser.touchMoveEvent, this.moveEvent);
            EventHandler.remove(this.element, Browser.touchEndEvent, this.endEvent);
            EventHandler.remove(this.element, Browser.touchCancelEvent, this.cancelEvent);
        };
        this.bind();
    }
    // triggers when property changed
    /**
     *
     * @private
     * @param {TouchModel} newProp ?
     * @param {TouchModel} oldProp ?
     * @returns {void} ?
     */
    // eslint-disable-next-line
    onPropertyChanged(newProp, oldProp) {
        //No Code to handle
    }
    bind() {
        this.wireEvents();
        if (Browser.isIE) {
            this.element.classList.add('e-block-touch');
        }
    }
    /**
     * To destroy the touch instance.
     *
     * @returns {void}
     */
    destroy() {
        this.unwireEvents();
        super.destroy();
    }
    // Need to changes the event binding once we updated the event handler.
    wireEvents() {
        EventHandler.add(this.element, Browser.touchStartEvent, this.startEvent, this);
    }
    unwireEvents() {
        EventHandler.remove(this.element, Browser.touchStartEvent, this.startEvent);
    }
    /**
     * Returns module name as touch
     *
     * @returns {string} ?
     * @private
     */
    getModuleName() {
        return 'touch';
    }
    /**
     * Returns if the HTML element is Scrollable.
     *
     * @param {HTMLElement} element - HTML Element to check if Scrollable.
     * @returns {boolean} ?
     */
    isScrollable(element) {
        const eleStyle = getComputedStyle(element);
        const style = eleStyle.overflow + eleStyle.overflowX + eleStyle.overflowY;
        if ((/(auto|scroll)/).test(style)) {
            return true;
        }
        return false;
    }
    /**
     *
     * @param {MouseEventArgs | TouchEventArgs} evt ?
     * @returns {void} ?
     */
    tapHoldEvent(evt) {
        this.tapCount = 0;
        this.touchAction = true;
        let eTapArgs;
        EventHandler.remove(this.element, Browser.touchMoveEvent, this.moveEvent);
        EventHandler.remove(this.element, Browser.touchEndEvent, this.endEvent);
        // eslint-disable-next-line
        eTapArgs = { originalEvent: evt };
        this.trigger('tapHold', eTapArgs);
        EventHandler.remove(this.element, Browser.touchCancelEvent, this.cancelEvent);
    }
    calcPoints(evt) {
        const point = this.updateChangeTouches(evt);
        this.defaultArgs = { originalEvent: evt };
        this.distanceX = Math.abs((Math.abs(point.clientX) - Math.abs(this.startPoint.clientX)));
        this.distanceY = Math.abs((Math.abs(point.clientY) - Math.abs(this.startPoint.clientY)));
        if (this.distanceX > this.distanceY) {
            this.movedDirection = (point.clientX > this.startPoint.clientX) ? 'Right' : 'Left';
        }
        else {
            this.movedDirection = (point.clientY < this.startPoint.clientY) ? 'Up' : 'Down';
        }
    }
    calcScrollPoints(evt) {
        const point = this.updateChangeTouches(evt);
        this.defaultArgs = { originalEvent: evt };
        this.distanceX = Math.abs((Math.abs(point.clientX) - Math.abs(this.lastMovedPoint.clientX)));
        this.distanceY = Math.abs((Math.abs(point.clientY) - Math.abs(this.lastMovedPoint.clientY)));
        if ((this.distanceX > this.distanceY || this.hScrollLocked === true) && this.vScrollLocked === false) {
            this.scrollDirection = (point.clientX > this.lastMovedPoint.clientX) ? 'Right' : 'Left';
            this.hScrollLocked = true;
        }
        else {
            this.scrollDirection = (point.clientY < this.lastMovedPoint.clientY) ? 'Up' : 'Down';
            this.vScrollLocked = true;
        }
    }
    getVelocity(pnt) {
        const newX = pnt.clientX;
        const newY = pnt.clientY;
        const newT = Date.now();
        const xDist = newX - this.startPoint.clientX;
        const yDist = newY - this.startPoint.clientX;
        const interval = newT - this.tStampStart;
        return Math.sqrt(xDist * xDist + yDist * yDist) / interval;
    }
    // eslint-disable-next-line
    checkSwipe(ele, flag) {
        const keys = ['scroll', 'offset'];
        const temp = flag ? ['Height', 'Top'] : ['Width', 'Left'];
        if ((ele[keys[0] + temp[0]] <= ele[keys[1] + temp[0]])) {
            return true;
        }
        return (ele[keys[0] + temp[1]] === 0) ||
            (ele[keys[1] + temp[0]] + ele[keys[0] + temp[1]] >= ele[keys[0] + temp[0]]);
    }
    updateChangeTouches(evt) {
        const point = evt.changedTouches && evt.changedTouches.length !== 0 ? evt.changedTouches[0] : evt;
        return point;
    }
};
__decorate([
    Event()
], Touch.prototype, "tap", void 0);
__decorate([
    Event()
], Touch.prototype, "tapHold", void 0);
__decorate([
    Event()
], Touch.prototype, "swipe", void 0);
__decorate([
    Event()
], Touch.prototype, "scroll", void 0);
__decorate([
    Property(350)
], Touch.prototype, "tapThreshold", void 0);
__decorate([
    Property(750)
], Touch.prototype, "tapHoldThreshold", void 0);
__decorate([
    Complex({}, SwipeSettings)
], Touch.prototype, "swipeSettings", void 0);
Touch = __decorate([
    NotifyPropertyChanges
], Touch);
export { Touch };
