import { CompressedStreamWriter } from './compression-writer';
import { Save } from '@syncfusion/ej2-file-utils';
const CRC32TABLE = [];
/**
 * class provide compression library
 * ```typescript
 * let archive = new ZipArchive();
 * archive.compressionLevel = 'Normal';
 * let archiveItem = new ZipArchiveItem(archive, 'directoryName\fileName.txt');
 * archive.addItem(archiveItem);
 * archive.save(fileName.zip);
 * ```
 */
export class ZipArchive {
    /**
     * gets compression level
     */
    get compressionLevel() {
        return this.level;
    }
    /**
     * sets compression level
     */
    set compressionLevel(level) {
        this.level = level;
    }
    /**
     * gets items count
     */
    get length() {
        if (this.files === undefined) {
            return 0;
        }
        return this.files.length;
    }
    /**
     * constructor for creating ZipArchive instance
     */
    constructor() {
        if (CRC32TABLE.length === 0) {
            ZipArchive.initCrc32Table();
        }
        this.files = [];
        this.level = 'Normal';
        Save.isMicrosoftBrowser = !(!navigator.msSaveBlob);
    }
    /**
     * add new item to archive
     * @param {ZipArchiveItem} item - item to be added
     * @returns {void}
     */
    addItem(item) {
        if (item === null || item === undefined) {
            throw new Error('ArgumentException: item cannot be null or undefined');
        }
        for (let i = 0; i < this.files.length; i++) {
            let file = this.files[i];
            if (file instanceof ZipArchiveItem) {
                if (file.name === item.name) {
                    throw new Error('item with same name already exist');
                }
            }
        }
        this.files.push(item);
    }
    /**
     * add new directory to archive
     * @param directoryName directoryName to be created
     * @returns {void}
     */
    addDirectory(directoryName) {
        if (directoryName === null || directoryName === undefined) {
            throw new Error('ArgumentException: string cannot be null or undefined');
        }
        if (directoryName.length === 0) {
            throw new Error('ArgumentException: string cannot be empty');
        }
        if (directoryName.slice(-1) !== '/') {
            directoryName += '/';
        }
        if (this.files.indexOf(directoryName) !== -1) {
            throw new Error('item with same name already exist');
        }
        this.files.push(directoryName);
    }
    /**
     * gets item at specified index
     * @param {number} index - item index
     * @returns {ZipArchiveItem}
     */
    getItem(index) {
        if (index >= 0 && index < this.files.length) {
            return this.files[index];
        }
        return undefined;
    }
    /**
     * determines whether an element is in the collection
     * @param {string | ZipArchiveItem} item - item to search
     * @returns {boolean}
     */
    contains(item) {
        return this.files.indexOf(item) !== -1 ? true : false;
    }
    /**
     * save archive with specified file name
     * @param {string} fileName save archive with specified file name
     * @returns {Promise<ZipArchive>}
     */
    save(fileName) {
        if (fileName === null || fileName === undefined || fileName.length === 0) {
            throw new Error('ArgumentException: fileName cannot be null or undefined');
        }
        if (this.files.length === 0) {
            throw new Error('InvalidOperation');
        }
        let zipArchive = this;
        let promise;
        return promise = new Promise((resolve, reject) => {
            zipArchive.saveInternal(fileName, false).then(() => {
                resolve(zipArchive);
            });
        });
    }
    /**
     * Save archive as blob
     * @return {Promise<Blob>}
     */
    saveAsBlob() {
        let zipArchive = this;
        let promise;
        return promise = new Promise((resolve, reject) => {
            zipArchive.saveInternal('', true).then((blob) => {
                resolve(blob);
            });
        });
    }
    saveInternal(fileName, skipFileSave) {
        let zipArchive = this;
        let promise;
        return promise = new Promise((resolve, reject) => {
            let zipData = [];
            let dirLength = 0;
            for (let i = 0; i < zipArchive.files.length; i++) {
                let compressedObject = this.getCompressedData(this.files[i]);
                compressedObject.then((data) => {
                    dirLength = zipArchive.constructZippedObject(zipData, data, dirLength, data.isDirectory);
                    if (zipData.length === zipArchive.files.length) {
                        let blob = zipArchive.writeZippedContent(fileName, zipData, dirLength, skipFileSave);
                        resolve(blob);
                    }
                });
            }
        });
    }
    /**
     * release allocated un-managed resource
     * @returns {void}
     */
    destroy() {
        if (this.files !== undefined && this.files.length > 0) {
            for (let i = 0; i < this.files.length; i++) {
                let file = this.files[i];
                if (file instanceof ZipArchiveItem) {
                    file.destroy();
                }
                file = undefined;
            }
            this.files = [];
        }
        this.files = undefined;
        this.level = undefined;
    }
    getCompressedData(item) {
        let zipArchive = this;
        let promise = new Promise((resolve, reject) => {
            if (item instanceof ZipArchiveItem) {
                let reader = new FileReader();
                reader.onload = () => {
                    let input = new Uint8Array(reader.result);
                    let data = {
                        fileName: item.name, crc32Value: 0, compressedData: [],
                        compressedSize: undefined, uncompressedDataSize: input.length, compressionType: undefined,
                        isDirectory: false
                    };
                    if (zipArchive.level === 'Normal') {
                        zipArchive.compressData(input, data, CRC32TABLE);
                        let length = 0;
                        for (let i = 0; i < data.compressedData.length; i++) {
                            length += data.compressedData[i].length;
                        }
                        data.compressedSize = length;
                        data.compressionType = '\x08\x00'; //Deflated = 8
                    }
                    else {
                        data.compressedSize = input.length;
                        data.crc32Value = zipArchive.calculateCrc32Value(0, input, CRC32TABLE);
                        data.compressionType = '\x00\x00'; // Stored = 0
                        data.compressedData.push(input);
                    }
                    resolve(data);
                };
                reader.readAsArrayBuffer(item.data);
            }
            else {
                let data = {
                    fileName: item, crc32Value: 0, compressedData: '', compressedSize: 0, uncompressedDataSize: 0,
                    compressionType: '\x00\x00', isDirectory: true
                };
                resolve(data);
            }
        });
        return promise;
    }
    compressData(input, data, crc32Table) {
        let compressor = new CompressedStreamWriter(true);
        let currentIndex = 0;
        let nextIndex = 0;
        do {
            if (currentIndex >= input.length) {
                compressor.close();
                break;
            }
            nextIndex = Math.min(input.length, currentIndex + 16384);
            let subArray = input.subarray(currentIndex, nextIndex);
            data.crc32Value = this.calculateCrc32Value(data.crc32Value, subArray, crc32Table);
            compressor.write(subArray, 0, nextIndex - currentIndex);
            currentIndex = nextIndex;
        } while (currentIndex <= input.length);
        data.compressedData = compressor.compressedData;
        compressor.destroy();
    }
    constructZippedObject(zipParts, data, dirLength, isDirectory) {
        let extFileAttr = 0;
        let date = new Date();
        if (isDirectory) {
            extFileAttr = extFileAttr | 0x00010; // directory flag
        }
        extFileAttr = extFileAttr | (0 & 0x3F);
        let header = this.writeHeader(data, date);
        let localHeader = 'PK\x03\x04' + header + data.fileName;
        let centralDir = this.writeCentralDirectory(data, header, dirLength, extFileAttr);
        zipParts.push({ localHeader: localHeader, centralDir: centralDir, compressedData: data });
        return dirLength + localHeader.length + data.compressedSize;
    }
    writeHeader(data, date) {
        let zipHeader = '';
        zipHeader += '\x0A\x00' + '\x00\x00'; // version needed to extract & general purpose bit flag
        zipHeader += data.compressionType; // compression method Deflate=8,Stored=0
        zipHeader += this.getBytes(this.getModifiedTime(date), 2); // last modified Time
        zipHeader += this.getBytes(this.getModifiedDate(date), 2); // last modified date
        zipHeader += this.getBytes(data.crc32Value, 4); // crc-32 value
        zipHeader += this.getBytes(data.compressedSize, 4); // compressed file size
        zipHeader += this.getBytes(data.uncompressedDataSize, 4); // uncompressed file size
        zipHeader += this.getBytes(data.fileName.length, 2); // file name length
        zipHeader += this.getBytes(0, 2); // extra field length
        return zipHeader;
    }
    writeZippedContent(fileName, zipData, localDirLen, skipFileSave) {
        let cenDirLen = 0;
        let buffer = [];
        for (let i = 0; i < zipData.length; i++) {
            let item = zipData[i];
            cenDirLen += item.centralDir.length;
            buffer.push(this.getArrayBuffer(item.localHeader));
            while (item.compressedData.compressedData.length) {
                buffer.push(item.compressedData.compressedData.shift().buffer);
            }
        }
        for (let i = 0; i < zipData.length; i++) {
            buffer.push(this.getArrayBuffer(zipData[i].centralDir));
        }
        buffer.push(this.getArrayBuffer(this.writeFooter(zipData, cenDirLen, localDirLen)));
        let blob = new Blob(buffer, { type: 'application/zip' });
        if (!skipFileSave) {
            Save.save(fileName, blob);
        }
        return blob;
    }
    writeCentralDirectory(data, localHeader, offset, externalFileAttribute) {
        let directoryHeader = 'PK\x01\x02' +
            this.getBytes(0x0014, 2) + localHeader + // inherit from file header
            this.getBytes(0, 2) + // comment length
            '\x00\x00' + '\x00\x00' + // internal file attributes 
            this.getBytes(externalFileAttribute, 4) + // external file attributes
            this.getBytes(offset, 4) + // local fileHeader relative offset
            data.fileName;
        return directoryHeader;
    }
    writeFooter(zipData, centralLength, localLength) {
        let dirEnd = 'PK\x05\x06' + '\x00\x00' + '\x00\x00' +
            this.getBytes(zipData.length, 2) + this.getBytes(zipData.length, 2) +
            this.getBytes(centralLength, 4) + this.getBytes(localLength, 4) +
            this.getBytes(0, 2);
        return dirEnd;
    }
    getArrayBuffer(input) {
        let a = new Uint8Array(input.length);
        for (let j = 0; j < input.length; ++j) {
            a[j] = input.charCodeAt(j) & 0xFF;
        }
        return a.buffer;
    }
    getBytes(value, offset) {
        let bytes = '';
        for (let i = 0; i < offset; i++) {
            bytes += String.fromCharCode(value & 0xff);
            value = value >>> 8;
        }
        return bytes;
    }
    getModifiedTime(date) {
        let modTime = date.getHours();
        modTime = modTime << 6;
        modTime = modTime | date.getMinutes();
        modTime = modTime << 5;
        return modTime = modTime | date.getSeconds() / 2;
    }
    getModifiedDate(date) {
        let modiDate = date.getFullYear() - 1980;
        modiDate = modiDate << 4;
        modiDate = modiDate | (date.getMonth() + 1);
        modiDate = modiDate << 5;
        return modiDate = modiDate | date.getDate();
    }
    calculateCrc32Value(crc32Value, input, crc32Table) {
        crc32Value ^= -1;
        for (let i = 0; i < input.length; i++) {
            crc32Value = (crc32Value >>> 8) ^ crc32Table[(crc32Value ^ input[i]) & 0xFF];
        }
        return (crc32Value ^ (-1));
    }
    /**
     * construct cyclic redundancy code table
     * @private
     */
    static initCrc32Table() {
        let i;
        for (let j = 0; j < 256; j++) {
            i = j;
            for (let k = 0; k < 8; k++) {
                i = ((i & 1) ? (0xEDB88320 ^ (i >>> 1)) : (i >>> 1));
            }
            CRC32TABLE[j] = i;
        }
    }
}
/**
 * Class represent unique ZipArchive item
 * ```typescript
 * let archiveItem = new ZipArchiveItem(archive, 'directoryName\fileName.txt');
 * ```
 */
export class ZipArchiveItem {
    /**
     * Get the name of archive item
     * @returns string
     */
    get name() {
        return this.fileName;
    }
    /**
     * Set the name of archive item
     * @param  {string} value
     */
    set name(value) {
        this.fileName = value;
    }
    /**
     * constructor for creating {ZipArchiveItem} instance
     * @param {Blob|ArrayBuffer} data file data
     * @param {itemName} itemName absolute file path
     */
    constructor(data, itemName) {
        if (data === null || data === undefined) {
            throw new Error('ArgumentException: data cannot be null or undefined');
        }
        if (itemName === null || itemName === undefined) {
            throw new Error('ArgumentException: string cannot be null or undefined');
        }
        if (itemName.length === 0) {
            throw new Error('string cannot be empty');
        }
        this.data = data;
        this.name = itemName;
    }
    /**
     * release allocated un-managed resource
     * @returns {void}
     */
    destroy() {
        this.fileName = undefined;
        this.data = undefined;
    }
}
