var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Property, ChildProperty } from '@syncfusion/ej2-base';
/**
 * Interface for a class Column
 */
/* istanbul ignore next */
export class Column extends ChildProperty {
}
__decorate([
    Property('')
], Column.prototype, "field", void 0);
__decorate([
    Property('')
], Column.prototype, "headerText", void 0);
__decorate([
    Property('')
], Column.prototype, "width", void 0);
__decorate([
    Property('')
], Column.prototype, "minWidth", void 0);
__decorate([
    Property('')
], Column.prototype, "maxWidth", void 0);
__decorate([
    Property('Left')
], Column.prototype, "textAlign", void 0);
__decorate([
    Property(null)
], Column.prototype, "headerTextAlign", void 0);
__decorate([
    Property(null)
], Column.prototype, "type", void 0);
__decorate([
    Property(null)
], Column.prototype, "format", void 0);
__decorate([
    Property(null)
], Column.prototype, "template", void 0);
__decorate([
    Property(null)
], Column.prototype, "headerTemplate", void 0);
__decorate([
    Property(true)
], Column.prototype, "allowSorting", void 0);
__decorate([
    Property(true)
], Column.prototype, "allowResizing", void 0);
__decorate([
    Property(null)
], Column.prototype, "customAttributes", void 0);
__decorate([
    Property('')
], Column.prototype, "hideAtMedia", void 0);
__decorate([
    Property(null)
], Column.prototype, "customFormat", void 0);
