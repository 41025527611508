/**
 * BlobHelper class
 * @private
 */
export class BlobHelper {
    constructor() {
        /* tslint:disable:no-any */
        this.parts = [];
    }
    /* tslint:disable:no-any */
    append(part) {
        this.parts.push(part);
        this.blob = undefined; // Invalidate the blob
    }
    getBlob() {
        return new Blob(this.parts, { type: 'text/plain' });
    }
}
