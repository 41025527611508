import { NgModule, ValueProvider } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RichTextEditorComponent } from './richtexteditor.component';
import { RichTextEditorModule } from './richtexteditor.module';
import {Toolbar, Link, Image, Count, QuickToolbar, HtmlEditor, MarkdownEditor, Table, PasteCleanup, Resize, FileManager} from '@syncfusion/ej2-richtexteditor';


export const /** @type {?} */ ToolbarService: ValueProvider = { provide: 'RichTextEditorToolbar', useValue: Toolbar};
export const /** @type {?} */ LinkService: ValueProvider = { provide: 'RichTextEditorLink', useValue: Link};
export const /** @type {?} */ ImageService: ValueProvider = { provide: 'RichTextEditorImage', useValue: Image};
export const /** @type {?} */ CountService: ValueProvider = { provide: 'RichTextEditorCount', useValue: Count};
export const /** @type {?} */ QuickToolbarService: ValueProvider = { provide: 'RichTextEditorQuickToolbar', useValue: QuickToolbar};
export const /** @type {?} */ HtmlEditorService: ValueProvider = { provide: 'RichTextEditorHtmlEditor', useValue: HtmlEditor};
export const /** @type {?} */ MarkdownEditorService: ValueProvider = { provide: 'RichTextEditorMarkdownEditor', useValue: MarkdownEditor};
export const /** @type {?} */ TableService: ValueProvider = { provide: 'RichTextEditorTable', useValue: Table};
export const /** @type {?} */ PasteCleanupService: ValueProvider = { provide: 'RichTextEditorPasteCleanup', useValue: PasteCleanup};
export const /** @type {?} */ ResizeService: ValueProvider = { provide: 'RichTextEditorResize', useValue: Resize};
export const /** @type {?} */ FileManagerService: ValueProvider = { provide: 'RichTextEditorFileManager', useValue: FileManager};
/**
 * NgModule definition for the RichTextEditor component with providers.
 */
export class RichTextEditorAllModule { static decorators: DecoratorInvocation[] = [
{ type: NgModule, args: [{
    imports: [CommonModule, RichTextEditorModule],
    exports: [
        RichTextEditorModule
    ],
    providers:[
        ToolbarService,
        LinkService,
        ImageService,
        CountService,
        QuickToolbarService,
        HtmlEditorService,
        MarkdownEditorService,
        TableService,
        PasteCleanupService,
        ResizeService,
        FileManagerService
    ]
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

function RichTextEditorAllModule_tsickle_Closure_declarations() {
/** @type {?} */
RichTextEditorAllModule.decorators;
/**
 * @nocollapse
 * @type {?}
 */
RichTextEditorAllModule.ctorParameters;
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
