/**
 * Specifies the File Manager internal variables
 */
/** @hidden */
export const isFile = 'isFile';
/**
 * Specifies the File Manager internal events
 */
/** @hidden */
export const modelChanged = 'model-changed';
/** @hidden */
export const initialEnd = 'initial-end';
/** @hidden */
export const finalizeEnd = 'finalize-end';
/** @hidden */
export const createEnd = 'create-end';
/** @hidden */
export const filterEnd = 'filter-end';
/** @hidden */
export const beforeDelete = 'before-delete';
/** @hidden */
export const pathDrag = 'path-drag';
/** @hidden */
export const deleteInit = 'delete-init';
/** @hidden */
export const deleteEnd = 'delete-end';
/** @hidden */
export const refreshEnd = 'refresh-end';
/** @hidden */
export const resizeEnd = 'resize-end';
/** @hidden */
export const splitterResize = 'splitter-resize';
/** @hidden */
export const pathChanged = 'path-changed';
/** @hidden */
export const destroy = 'destroy';
/** @hidden */
export const beforeRequest = 'before-request';
/** @hidden */
export const upload = 'upload';
/** @hidden */
export const skipUpload = 'skip-upload';
/** @hidden */
export const afterRequest = 'after-request';
/** @hidden */
export const download = 'download';
/** @hidden */
export const layoutRefresh = 'layout-refresh';
/** @hidden */
export const actionFailure = 'actionFailure';
/** @hidden */
export const search = 'search';
/** @hidden */
export const openInit = 'open-init';
/** @hidden */
export const openEnd = 'open-end';
/** @hidden */
export const selectionChanged = 'selection-changed';
/** @hidden */
export const selectAllInit = 'select-all-init';
/** @hidden */
export const clearAllInit = 'clear-all-init';
/** @hidden */
export const clearPathInit = 'clear-path-init';
/** @hidden */
export const layoutChange = 'layout-change';
/** @hidden */
export const sortByChange = 'sort-by-change';
/** @hidden */
export const nodeExpand = 'node-expand';
/** @hidden */
export const detailsInit = 'details-init';
/** @hidden */
export const menuItemData = 'menu-item-data';
/** @hidden */
export const renameInit = 'rename-init';
/** @hidden */
export const renameEndParent = 'rename-end-parent';
/** @hidden */
export const renameEnd = 'rename-end';
/** @hidden */
export const showPaste = 'show-paste';
/** @hidden */
export const hidePaste = 'hide-paste';
/** @hidden */
export const selectedData = 'selected-data';
/** @hidden */
export const cutCopyInit = 'cut-copy-init';
/** @hidden */
export const pasteInit = 'paste-init';
/** @hidden */
export const pasteEnd = 'paste-end';
/** @hidden */
export const cutEnd = 'cut-end';
/** @hidden */
export const hideLayout = 'hide-layout';
/** @hidden */
export const updateTreeSelection = 'update-tree-selection';
/** @hidden */
export const treeSelect = 'select-node';
/** @hidden */
export const sortColumn = 'sort-column';
/** @hidden */
export const pathColumn = 'path-column';
/** @hidden */
export const searchTextChange = 'search-change';
/** @hidden */
export const beforeDownload = 'before-download';
/** @hidden */
export const downloadInit = 'download-init';
/** @hidden */
export const dropInit = 'drop-init';
/** @hidden */
export const dragEnd = 'drag-end';
/** @hidden */
export const dropPath = 'drop-path';
/** @hidden */
export const dragHelper = 'drag-helper';
/** @hidden */
export const dragging = 'dragging';
/** @hidden */
export const updateSelectionData = 'update-selection-data';
/** @hidden */
export const methodCall = 'method-call';
/** @hidden */
export const permissionRead = 'read';
/** @hidden */
export const permissionEdit = 'write';
/** @hidden */
export const permissionEditContents = 'writeContents';
/** @hidden */
export const permissionCopy = 'copy';
/** @hidden */
export const permissionUpload = 'upload';
/** @hidden */
export const permissionDownload = 'download';
