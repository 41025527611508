var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Collection, Event, NotifyPropertyChanges, detach, Property, EventHandler } from '@syncfusion/ej2-base';
import { addClass, getUniqueID, rippleEffect, getComponent } from '@syncfusion/ej2-base';
import { attributes, Component, closest, select, SanitizeHtmlHelper } from '@syncfusion/ej2-base';
import { classList, removeClass } from '@syncfusion/ej2-base';
import { Button } from '@syncfusion/ej2-buttons';
import { Popup } from '@syncfusion/ej2-popups';
import { upDownKeyHandler } from './../common/common';
import { getModel, Item, setBlankIconStyle } from './../common/common';
const classNames = {
    DISABLED: 'e-disabled',
    FOCUS: 'e-focused',
    ICON: 'e-menu-icon',
    ITEM: 'e-item',
    POPUP: 'e-dropdown-popup',
    RTL: 'e-rtl',
    SEPARATOR: 'e-separator',
    VERTICAL: 'e-vertical'
};
/**
 * DropDownButton component is used to toggle contextual overlays for displaying list of action items.
 * It can contain both text and images.
 * ```html
 * <button id="element">DropDownButton</button>
 * ```
 * ```typescript
 * <script>
 * var dropDownButtonObj = new DropDownButton({items: [{ text: 'Action1' }, { text: 'Action2' },{ text: 'Action3' }]);
 * dropDownButtonObj.appendTo("#element");
 * </script>
 * ```
 */
let DropDownButton = class DropDownButton extends Component {
    /**
     * Constructor for creating the widget
     *
     * @param  {DropDownButtonModel} options - Specifies dropdown button model
     * @param  {string|HTMLButtonElement} element - Specifies element
     * @hidden
     */
    constructor(options, element) {
        super(options, element);
        this.isPopupCreated = true;
    }
    preRender() {
        /** */
    }
    /**
     * Get the properties to be maintained in the persisted state.
     *
     * @returns {string} - Persist data
     */
    getPersistData() {
        return this.addOnPersist([]);
    }
    /**
     * To open/close DropDownButton popup based on current state of the DropDownButton.
     *
     * @returns {void}
     */
    toggle() {
        if (this.canOpen()) {
            this.openPopUp();
        }
        else {
            this.closePopup();
        }
    }
    /**
     * Initialize the Component rendering
     *
     * @returns {void}
     * @private
     */
    render() {
        this.initialize();
        if (!this.disabled) {
            this.wireEvents();
        }
        this.renderComplete();
    }
    /**
     * Adds a new item to the menu. By default, new item appends to the list as the last item,
     * but you can insert based on the text parameter.
     *
     * @param  { ItemModel[] } items - Specifies an array of JSON data.
     * @param { string } text - Specifies the text to insert the newly added item in the menu.
     * @returns {void}.
     */
    addItems(items, text) {
        let newItem;
        let idx = this.items.length;
        for (let j = 0, len = this.items.length; j < len; j++) {
            if (text === this.items[j].text) {
                idx = j;
                break;
            }
        }
        for (let i = items.length - 1; i >= 0; i--) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            newItem = new Item(this, 'items', items[i], true);
            this.items.splice(idx, 0, newItem);
        }
        if (!this.canOpen()) {
            this.createItems();
        }
    }
    /**
     * Removes the items from the menu.
     *
     * @param  { string[] } items - Specifies an array of string to remove the items.
     * @param { string } isUniqueId - Set `true` if specified items is a collection of unique id.
     * @returns {void}.
     */
    removeItems(items, isUniqueId) {
        let refresh = false;
        for (let i = 0, len = items.length; i < len; i++) {
            for (let j = 0, len = this.items.length; j < len; j++) {
                if (items[i] === (isUniqueId ? this.items[j].id : this.items[j].text)) {
                    this.items.splice(j, 1);
                    refresh = true;
                    break;
                }
            }
        }
        if (refresh && this.getULElement()) {
            this.createItems();
        }
    }
    createPopup() {
        const div = this.createElement('div', {
            className: classNames.POPUP,
            id: this.element.id + '-popup'
        });
        document.body.appendChild(div);
        this.dropDown = new Popup(div, {
            relateTo: this.element,
            collision: { X: 'fit', Y: 'flip' },
            position: { X: 'left', Y: 'bottom' },
            targetType: 'relative',
            content: this.target ? this.getTargetElement() : '',
            enableRtl: this.enableRtl
        });
        if (this.dropDown.element.style.position === 'fixed') {
            this.dropDown.refreshPosition(this.element);
        }
        this.dropDown.hide();
        attributes(this.element, {
            ['aria-haspopup']: this.items.length || this.target ? 'true' : 'false', ['aria-expanded']: 'false',
            ['aria-owns']: this.getPopUpElement().id, ['type']: 'button',
            ['aria-label']: this.element.textContent ? this.element.textContent : 'dropdownbutton'
        });
        if (this.cssClass) {
            addClass([div], this.cssClass.split(' '));
        }
        this.isPopupCreated = true;
    }
    getTargetElement() {
        return typeof (this.target) === 'string' ? select(this.target) : this.target;
    }
    createItems(appendItems) {
        const items = this.items;
        const showIcon = this.hasIcon(this.items, 'iconCss');
        let span;
        let item;
        let li;
        let eventArgs;
        let ul = this.getULElement();
        if (ul) {
            ul.innerHTML = '';
        }
        else {
            ul = this.createElement('ul', {
                attrs: { 'role': 'menu', 'tabindex': '0' }
            });
        }
        for (let i = 0; i < items.length; i++) {
            item = items[i];
            const tempItem = item.text;
            li = this.createElement('li', {
                innerHTML: item.url ? '' : tempItem,
                className: item.separator ? classNames.ITEM + ' ' + classNames.SEPARATOR : classNames.ITEM,
                attrs: { 'role': 'menuItem', 'tabindex': '-1', 'aria-label': tempItem },
                id: item.id ? item.id : getUniqueID('e-' + this.getModuleName() + '-item')
            });
            if (this.enableHtmlSanitizer) {
                li.textContent = item.url ? '' : tempItem;
            }
            else {
                li.innerHTML = item.url ? '' : tempItem;
            }
            if (item.url) {
                li.appendChild(this.createAnchor(item));
                li.classList.add('e-url');
            }
            if (item.iconCss) {
                span = this.createElement('span', { className: classNames.ICON + ' ' + item.iconCss });
                if (item.url) {
                    li.childNodes[0].appendChild(span);
                }
                else {
                    li.insertBefore(span, li.childNodes[0]);
                }
            }
            else {
                if (showIcon && !item.separator) {
                    li.classList.add('e-blank-icon');
                }
            }
            if (item.disabled) {
                li.classList.add('e-disabled');
            }
            eventArgs = { item: item, element: li };
            this.trigger('beforeItemRender', eventArgs);
            ul.appendChild(li);
        }
        if (appendItems) {
            this.getPopUpElement().appendChild(ul);
        }
        if (showIcon) {
            setBlankIconStyle(this.getPopUpElement());
        }
    }
    hasIcon(items, field) {
        for (let i = 0, len = items.length; i < len; i++) {
            if (items[i][field]) {
                return true;
            }
        }
        return false;
    }
    createAnchor(item) {
        const tempItem = (this.enableHtmlSanitizer) ? SanitizeHtmlHelper.sanitize(item.text) : item.text;
        return this.createElement('a', { className: 'e-menu-text e-menu-url', innerHTML: tempItem, attrs: { 'href': item.url } });
    }
    initialize() {
        this.button = new Button({
            iconCss: this.iconCss, iconPosition: this.iconPosition, cssClass: this.cssClass, content: this.content,
            disabled: this.disabled, enableRtl: this.enableRtl, enablePersistence: this.enablePersistence
        });
        this.button.createElement = this.createElement;
        this.button.appendTo(this.element);
        if (!this.element.id) {
            this.element.id = getUniqueID('e-' + this.getModuleName());
        }
        this.appendArrowSpan();
        this.setActiveElem([this.element]);
        if ((this.target && !this.isColorPicker()) || !this.createPopupOnClick) {
            this.createPopup();
        }
        else {
            this.isPopupCreated = false;
        }
    }
    isColorPicker() {
        if (!this.element) {
            return false;
        }
        const prevElem = this.element.previousSibling;
        if (prevElem && prevElem.classList && prevElem.classList.contains('e-split-colorpicker')) {
            return true;
        }
        return false;
    }
    appendArrowSpan() {
        this.element.appendChild(this.createElement('span', {
            className: 'e-btn-icon e-icons ' + 'e-icon-' + (this.cssClass.indexOf(classNames.VERTICAL) > -1
                ? 'bottom' : 'right') + ' e-caret'
        }));
    }
    setActiveElem(elem) {
        this.activeElem = elem;
    }
    /**
     * Get component name.
     *
     * @returns {string} - Module Name
     * @private
     */
    getModuleName() {
        return 'dropdown-btn';
    }
    canOpen() {
        let val = false;
        if (this.isPopupCreated) {
            val = this.getPopUpElement().classList.contains('e-popup-close');
        }
        return val;
    }
    /**
     * Destroys the widget.
     *
     * @returns {void}
     */
    destroy() {
        super.destroy();
        if (this.getModuleName() === 'dropdown-btn') {
            let classList;
            if (this.element.querySelector('span.e-caret')) {
                detach(this.element.querySelector('span.e-caret'));
            }
            if (this.cssClass) {
                classList = this.cssClass.split(' ');
            }
            this.button.destroy();
            if (classList) {
                removeClass([this.element], classList);
            }
            removeClass(this.activeElem, ['e-active']);
            const attrList = this.element.getAttribute('class') ? ['aria-haspopup', 'aria-expanded', 'aria-owns', 'type']
                : ['aria-haspopup', 'aria-expanded', 'aria-owns', 'type', 'class'];
            attrList.forEach((key) => {
                this.element.removeAttribute(key);
            });
            this.popupUnWireEvents();
            this.destroyPopup();
            this.isPopupCreated = false;
            if (!this.disabled) {
                this.unWireEvents();
            }
        }
    }
    destroyPopup() {
        if (this.isPopupCreated) {
            this.dropDown.destroy();
            if (this.getPopUpElement()) {
                const popupEle = document.getElementById(this.getPopUpElement().id);
                if (popupEle) {
                    removeClass([popupEle], ['e-popup-open', 'e-popup-close']);
                    detach(popupEle);
                }
            }
            EventHandler.remove(this.getPopUpElement(), 'click', this.clickHandler);
            EventHandler.remove(this.getPopUpElement(), 'keydown', this.keyBoardHandler);
            if (this.isPopupCreated) {
                this.dropDown = undefined;
            }
        }
        this.isPopupCreated = false;
    }
    getPopUpElement() {
        let val = null;
        if (this.dropDown) {
            val = this.dropDown.element;
        }
        return val;
    }
    getULElement() {
        let val = null;
        if (this.getPopUpElement()) {
            val = this.getPopUpElement().children[0];
        }
        return val;
    }
    wireEvents() {
        this.delegateMousedownHandler = this.mousedownHandler.bind(this);
        if (!this.createPopupOnClick) {
            EventHandler.add(document, 'mousedown touchstart', this.delegateMousedownHandler, this);
        }
        EventHandler.add(this.element, 'click', this.clickHandler, this);
        EventHandler.add(this.element, 'keydown', this.keyBoardHandler, this);
    }
    popupWireEvents() {
        const popupElement = this.getPopUpElement();
        if (this.createPopupOnClick) {
            EventHandler.add(document, 'mousedown touchstart', this.delegateMousedownHandler, this);
        }
        if (popupElement) {
            EventHandler.add(popupElement, 'click', this.clickHandler, this);
            EventHandler.add(popupElement, 'keydown', this.keyBoardHandler, this);
            if (this.closeActionEvents) {
                EventHandler.add(popupElement, this.closeActionEvents, this.focusoutHandler, this);
            }
        }
        this.rippleFn = rippleEffect(popupElement, { selector: '.' + classNames.ITEM });
    }
    popupUnWireEvents() {
        const popupElement = this.getPopUpElement();
        if (this.createPopupOnClick) {
            EventHandler.remove(document, 'mousedown touchstart', this.delegateMousedownHandler);
        }
        if (popupElement && popupElement.parentElement) {
            EventHandler.remove(popupElement, 'click', this.clickHandler);
            EventHandler.remove(popupElement, 'keydown', this.keyBoardHandler);
            if (this.closeActionEvents) {
                EventHandler.remove(popupElement, this.closeActionEvents, this.focusoutHandler);
            }
        }
    }
    /**
     * Handles the keyboard interactions.
     *
     * @param {KeyboardEventArgs} e - Specifies keyboard event args.
     * @returns {void}
     * @hidden
     */
    keyBoardHandler(e) {
        if (e.target === this.element && (e.keyCode === 9 || (!e.altKey && e.keyCode === 40) || e.keyCode === 38)) {
            return;
        }
        switch (e.keyCode) {
            case 38:
            case 40:
                if (e.altKey && (e.keyCode === 38 || e.keyCode === 40)) {
                    this.keyEventHandler(e);
                }
                else {
                    this.upDownKeyHandler(e);
                }
                break;
            case 9:
            case 13:
            case 27:
            case 32:
                this.keyEventHandler(e);
                break;
        }
    }
    upDownKeyHandler(e) {
        if (this.target && (e.keyCode === 38 || e.keyCode === 40)) {
            return;
        }
        e.preventDefault();
        upDownKeyHandler(this.getULElement(), e.keyCode);
    }
    keyEventHandler(e) {
        if (this.target && (e.keyCode === 13 || e.keyCode === 9)) {
            return;
        }
        if (e.target && e.target.className.indexOf('e-edit-template') > -1 && e.keyCode === 32) {
            return;
        }
        if (e.keyCode !== 9) {
            e.preventDefault();
        }
        if (e.keyCode === 27 || e.keyCode === 38 || e.keyCode === 9) {
            if (!this.canOpen()) {
                this.closePopup(e, this.element);
            }
        }
        else {
            this.clickHandler(e);
        }
    }
    getLI(elem) {
        return elem.tagName === 'LI' ? elem : closest(elem, 'li');
    }
    mousedownHandler(e) {
        const trgt = e.target;
        if (this.dropDown && !this.canOpen() && !(closest(trgt, '[id="' + this.getPopUpElement().id + '"]')
            || closest(trgt, '[id="' + this.element.id + '"]'))) {
            this.closePopup(e);
        }
    }
    focusoutHandler(e) {
        if (this.isPopupCreated && !this.canOpen()) {
            this.closePopup(e);
        }
    }
    clickHandler(e) {
        const trgt = e.target;
        if (closest(trgt, '[id="' + this.element.id + '"]')) {
            if (!this.createPopupOnClick || (this.target && !this.isColorPicker())) {
                if (this.getPopUpElement().classList.contains('e-popup-close')) {
                    this.openPopUp(e);
                }
                else {
                    this.closePopup(e);
                }
            }
            else if (this.isPopupCreated) {
                this.closePopup(e, this.activeElem[0]);
            }
            else {
                this.createPopup();
                this.openPopUp(e);
            }
        }
        else {
            if (closest(trgt, '[id="' + this.getPopUpElement().id + '"]')) {
                let eventArgs;
                let liIdx;
                let item;
                const li = this.getLI(trgt);
                if (li) {
                    liIdx = Array.prototype.indexOf.call(this.getULElement().children, li);
                    item = this.items[liIdx];
                    if (item) {
                        eventArgs = { element: li, item: item };
                        this.trigger('select', eventArgs);
                    }
                    this.closePopup(e, this.activeElem[0]);
                }
            }
        }
    }
    openPopUp(e = null) {
        let isReact = false;
        const popupElem = this.getPopUpElement();
        if (!this.target) {
            this.createItems(true);
        }
        else {
            if (this.activeElem.length > 1) {
                let splitButton = getComponent(this.activeElem[0], 'split-btn');
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                if (splitButton.isReact && popupElem.childNodes.length < 1) {
                    isReact = true;
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    splitButton.appendReactElement(this.getTargetElement(), this.getPopUpElement());
                    this.renderReactTemplates();
                }
            }
            else {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                if (this.isReact && popupElem.childNodes.length < 1) {
                    isReact = true;
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    this.appendReactElement(this.getTargetElement(), this.getPopUpElement());
                    this.renderReactTemplates();
                }
            }
        }
        const ul = this.getULElement();
        this.popupWireEvents();
        const beforeOpenArgs = { element: ul, items: this.items, event: e, cancel: false };
        this.trigger('beforeOpen', beforeOpenArgs, (observedArgs) => {
            if (!observedArgs.cancel) {
                const ul = this.getULElement();
                this.dropDown.show(null, this.element);
                addClass([this.element], 'e-active');
                this.element.setAttribute('aria-expanded', 'true');
                if (ul) {
                    ul.focus();
                }
                const openArgs = { element: ul, items: this.items };
                this.trigger('open', openArgs);
            }
        });
    }
    closePopup(e = null, focusEle) {
        const ul = this.getULElement();
        const beforeCloseArgs = { element: ul, items: this.items, event: e, cancel: false };
        let popupElement = this.getPopUpElement();
        if (popupElement) {
            EventHandler.remove(popupElement, 'keydown', this.keyBoardHandler);
        }
        this.trigger('beforeClose', beforeCloseArgs, (observedArgs) => {
            if (!observedArgs.cancel) {
                this.popupUnWireEvents();
                const ul = this.getULElement();
                let selectedLi;
                if (ul) {
                    selectedLi = ul.querySelector('.e-selected');
                }
                if (selectedLi) {
                    selectedLi.classList.remove('e-selected');
                }
                this.dropDown.hide();
                removeClass(this.activeElem, 'e-active');
                this.element.setAttribute('aria-expanded', 'false');
                if (focusEle) {
                    focusEle.focus();
                }
                const closeArgs = { element: ul, items: this.items };
                this.trigger('close', closeArgs);
                if (!this.target && ul) {
                    detach(ul);
                }
                if (!this.target || this.isColorPicker()) {
                    if (this.createPopupOnClick) {
                        this.destroyPopup();
                    }
                }
                if (this.target) {
                    this.isPopupCreated = this.createPopupOnClick ? false : true;
                }
            }
        });
    }
    unWireEvents() {
        if (!this.createPopupOnClick) {
            EventHandler.remove(document, 'mousedown touchstart', this.delegateMousedownHandler);
        }
        EventHandler.remove(this.element, 'click', this.clickHandler);
        EventHandler.remove(this.element, 'keydown', this.keyBoardHandler);
        if (this.isPopupCreated) {
            EventHandler.remove(this.getPopUpElement(), 'click', this.clickHandler);
            EventHandler.remove(this.getPopUpElement(), 'keydown', this.keyBoardHandler);
        }
    }
    /**
     * Called internally if any of the property value changed.
     *
     * @param  {DropDownButtonModel} newProp - Specifies new properties
     * @param  {DropDownButtonModel} oldProp - Specifies old properties
     * @returns {void}
     * @private
     */
    onPropertyChanged(newProp, oldProp) {
        const btnModel = ['content', 'cssClass', 'iconCss', 'iconPosition', 'disabled', 'enableRtl'];
        this.button.setProperties(getModel(newProp, btnModel));
        let popupElement;
        if (this.isPopupCreated) {
            popupElement = this.getPopUpElement();
            this.dropDown.setProperties(getModel(newProp, ['enableRtl']));
        }
        for (const prop of Object.keys(newProp)) {
            switch (prop) {
                case 'content':
                    if (!this.element.querySelector('span.e-caret')) {
                        this.appendArrowSpan();
                    }
                    break;
                case 'disabled':
                    if (newProp.disabled) {
                        this.unWireEvents();
                        if (this.isPopupCreated && !this.canOpen()) {
                            this.closePopup();
                        }
                    }
                    else {
                        this.wireEvents();
                    }
                    break;
                case 'cssClass':
                    if (newProp.cssClass.indexOf(classNames.VERTICAL) > -1) {
                        const arrowSpan = this.element.querySelector('span.e-caret');
                        classList(arrowSpan, ['e-icon-bottom'], ['e-icon-right']);
                    }
                    if (this.isPopupCreated) {
                        if (oldProp.cssClass) {
                            removeClass([popupElement], oldProp.cssClass.split(' '));
                        }
                        if (newProp.cssClass) {
                            addClass([popupElement], newProp.cssClass.split(' '));
                        }
                    }
                    break;
                case 'target':
                    this.dropDown.content = this.getTargetElement();
                    this.dropDown.dataBind();
                    break;
                case 'items':
                    if (this.isPopupCreated && this.getULElement()) {
                        this.createItems();
                    }
                    break;
                case 'createPopupOnClick':
                    if (newProp.createPopupOnClick) {
                        this.destroyPopup();
                    }
                    else {
                        this.createPopup();
                    }
                    break;
            }
        }
    }
    /**
     * Sets the focus to DropDownButton
     * its native method
     *
     * @public
     * @returns {void}
     */
    focusIn() {
        this.element.focus();
    }
};
__decorate([
    Property('')
], DropDownButton.prototype, "content", void 0);
__decorate([
    Property('')
], DropDownButton.prototype, "cssClass", void 0);
__decorate([
    Property(false)
], DropDownButton.prototype, "disabled", void 0);
__decorate([
    Property('')
], DropDownButton.prototype, "iconCss", void 0);
__decorate([
    Property('Left')
], DropDownButton.prototype, "iconPosition", void 0);
__decorate([
    Property(false)
], DropDownButton.prototype, "enableHtmlSanitizer", void 0);
__decorate([
    Collection([], Item)
], DropDownButton.prototype, "items", void 0);
__decorate([
    Property(false)
], DropDownButton.prototype, "createPopupOnClick", void 0);
__decorate([
    Property('')
], DropDownButton.prototype, "target", void 0);
__decorate([
    Property('')
], DropDownButton.prototype, "closeActionEvents", void 0);
__decorate([
    Event()
], DropDownButton.prototype, "beforeItemRender", void 0);
__decorate([
    Event()
], DropDownButton.prototype, "beforeOpen", void 0);
__decorate([
    Event()
], DropDownButton.prototype, "beforeClose", void 0);
__decorate([
    Event()
], DropDownButton.prototype, "close", void 0);
__decorate([
    Event()
], DropDownButton.prototype, "open", void 0);
__decorate([
    Event()
], DropDownButton.prototype, "select", void 0);
__decorate([
    Event()
], DropDownButton.prototype, "created", void 0);
DropDownButton = __decorate([
    NotifyPropertyChanges
], DropDownButton);
export { DropDownButton };
