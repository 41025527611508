/**
 * Row class
 * @private
 */
export class Row {
}
/**
 * Rows class
 * @private
 */
export class Rows extends Array {
    constructor() {
        super(...arguments);
        this.add = (row) => {
            this.push(row);
        };
    }
}
