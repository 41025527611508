/**
 * Specifies the File Manager internal ID's
 */
/** @hidden */
export const TOOLBAR_ID = '_toolbar';
/** @hidden */
export const LAYOUT_ID = '_layout';
/** @hidden */
export const NAVIGATION_ID = '_navigation';
/** @hidden */
export const TREE_ID = '_tree';
/** @hidden */
export const GRID_ID = '_grid';
/** @hidden */
export const LARGEICON_ID = '_largeicons';
/** @hidden */
export const DIALOG_ID = '_dialog';
/** @hidden */
export const ALT_DIALOG_ID = '_alt_dialog';
/** @hidden */
export const IMG_DIALOG_ID = '_img_dialog';
/** @hidden */
export const EXTN_DIALOG_ID = '_extn_dialog';
/** @hidden */
export const UPLOAD_DIALOG_ID = '_upload_dialog';
/** @hidden */
export const RETRY_DIALOG_ID = '_retry_dialog';
/** @hidden */
export const CONTEXT_MENU_ID = '_contextmenu';
/** @hidden */
export const SORTBY_ID = '_sortby';
/** @hidden */
export const VIEW_ID = '_view';
/** @hidden */
export const SPLITTER_ID = '_splitter';
/** @hidden */
export const CONTENT_ID = '_content';
/** @hidden */
export const BREADCRUMBBAR_ID = '_breadcrumbbar';
/** @hidden */
export const UPLOAD_ID = '_upload';
/** @hidden */
export const RETRY_ID = '_retry';
/** @hidden */
export const SEARCH_ID = '_search';
/**
 * Specifies the File Manager internal class names
 */
/** @hidden */
export const ROOT = 'e-filemanager';
/** @hidden */
export const CONTROL = 'e-control';
/** @hidden */
export const CHECK_SELECT = 'e-fe-cb-select';
/** @hidden */
export const ROOT_POPUP = 'e-fe-popup';
/** @hidden */
export const MOBILE = 'e-fe-mobile';
/** @hidden */
export const MOB_POPUP = 'e-fe-popup e-fe-mobile';
/** @hidden */
export const MULTI_SELECT = 'e-fe-m-select';
/** @hidden */
export const FILTER = 'e-fe-m-filter';
/** @hidden */
export const LAYOUT = 'e-layout';
/** @hidden */
export const NAVIGATION = 'e-navigation';
/** @hidden */
export const LAYOUT_CONTENT = 'e-layout-content';
/** @hidden */
export const LARGE_ICONS = 'e-large-icons';
/** @hidden */
export const TB_ITEM = 'e-toolbar-item';
/** @hidden */
export const LIST_ITEM = 'e-list-item';
/** @hidden */
export const LIST_TEXT = 'e-list-text';
/** @hidden */
export const LIST_PARENT = 'e-list-parent';
/** @hidden */
export const TB_OPTION_TICK = 'e-icons e-fe-tick';
/** @hidden */
export const TB_OPTION_DOT = 'e-icons e-fe-dot';
/** @hidden */
export const BLUR = 'e-blur';
/** @hidden */
export const ACTIVE = 'e-active';
/** @hidden */
export const HOVER = 'e-hover';
/** @hidden */
export const FOCUS = 'e-focus';
/** @hidden */
export const FOCUSED = 'e-focused';
/** @hidden */
export const CHECK = 'e-check';
/** @hidden */
export const FRAME = 'e-frame';
/** @hidden */
export const CB_WRAP = 'e-checkbox-wrapper';
/** @hidden */
export const ROW = 'e-row';
/** @hidden */
export const ROWCELL = 'e-rowcell';
/** @hidden */
export const EMPTY = 'e-empty';
/** @hidden */
export const EMPTY_CONTENT = 'e-empty-content';
/** @hidden */
export const EMPTY_INNER_CONTENT = 'e-empty-inner-content';
/** @hidden */
export const CLONE = 'e-fe-clone';
/** @hidden */
export const DROP_FOLDER = 'e-fe-drop-folder';
/** @hidden */
export const DROP_FILE = 'e-fe-drop-file';
/** @hidden */
export const FOLDER = 'e-fe-folder';
/** @hidden */
export const ICON_IMAGE = 'e-fe-image';
/** @hidden */
export const ICON_MUSIC = 'e-fe-music';
/** @hidden */
export const ICON_VIDEO = 'e-fe-video';
/** @hidden */
export const LARGE_ICON = 'e-large-icon';
/** @hidden */
export const LARGE_EMPTY_FOLDER = 'e-empty-icon e-fe-folder';
/** @hidden */
export const LARGE_EMPTY_FOLDER_TWO = 'e-empty-icon.e-fe-folder';
/** @hidden */
export const LARGE_ICON_FOLDER = 'e-fe-folder';
/** @hidden */
export const SELECTED_ITEMS = 'e-items';
/** @hidden */
export const TEXT_CONTENT = 'e-text-content';
/** @hidden */
export const GRID_HEADER = 'e-gridheader';
/** @hidden */
export const TEMPLATE_CELL = 'e-templatecell';
/** @hidden */
export const TREE_VIEW = 'e-treeview';
/** @hidden */
export const MENU_ITEM = 'e-menu-item';
/** @hidden */
export const MENU_ICON = 'e-menu-icon';
/** @hidden */
export const SUBMENU_ICON = 'e-caret';
/** @hidden */
export const GRID_VIEW = 'e-content';
/** @hidden */
export const GRID_CONTENT = 'e-gridcontent';
/** @hidden */
export const ICON_VIEW = 'e-list-parent';
/** @hidden */
export const ICON_OPEN = 'e-icons e-fe-open';
/** @hidden */
export const ICON_UPLOAD = 'e-icons e-fe-upload';
/** @hidden */
export const ICON_CUT = 'e-icons e-fe-cut';
/** @hidden */
export const ICON_COPY = 'e-icons e-fe-copy';
/** @hidden */
export const ICON_PASTE = 'e-icons e-fe-paste';
/** @hidden */
export const ICON_DELETE = 'e-icons e-fe-delete';
/** @hidden */
export const ICON_RENAME = 'e-icons e-fe-rename';
/** @hidden */
export const ICON_NEWFOLDER = 'e-icons e-fe-newfolder';
/** @hidden */
export const ICON_DETAILS = 'e-icons e-fe-details';
/** @hidden */
export const ICON_SHORTBY = 'e-icons e-fe-sort';
/** @hidden */
export const ICON_REFRESH = 'e-icons e-fe-refresh';
/** @hidden */
export const ICON_SELECTALL = 'e-icons e-fe-select';
/** @hidden */
export const ICON_DOWNLOAD = 'e-icons e-fe-download';
/** @hidden */
export const ICON_OPTIONS = 'e-icons e-fe-options';
/** @hidden */
export const ICON_GRID = 'e-icons e-fe-grid';
/** @hidden */
export const ICON_LARGE = 'e-icons e-fe-large';
/** @hidden */
export const ICON_BREADCRUMB = 'e-icons e-fe-breadcrumb';
/** @hidden */
export const ICON_CLEAR = 'e-icons e-fe-clear';
/** @hidden */
export const ICON_DROP_IN = 'e-icons e-fe-drop-in';
/** @hidden */
export const ICON_DROP_OUT = 'e-icons e-fe-drop-out';
/** @hidden */
export const ICON_NO_DROP = 'e-icons e-fe-no-drop';
/** @hidden */
export const ICONS = 'e-icons';
/** @hidden */
export const DETAILS_LABEL = 'e-detailslabel';
/** @hidden */
export const ERROR_CONTENT = 'e-fe-errorcontent';
/** @hidden */
export const STATUS = 'e-status';
/** @hidden */
export const BREADCRUMBS = 'e-address';
/** @hidden */
export const RTL = 'e-rtl';
/** @hidden */
export const DISPLAY_NONE = 'e-display-none';
/** @hidden */
export const COLLAPSED = 'e-node-collapsed';
/** @hidden */
export const FULLROW = 'e-fullrow';
/** @hidden */
export const ICON_COLLAPSIBLE = 'e-icon-collapsible';
/** @hidden */
export const SPLIT_BAR = 'e-split-bar';
/** @hidden */
export const HEADER_CHECK = 'e-headercheck';
/** @hidden */
export const OVERLAY = 'e-fe-overlay';
/** @hidden */
export const VALUE = 'e-fe-value';
