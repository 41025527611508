import { Component, ElementRef, ViewContainerRef, ValueProvider, Renderer2, Injector, ChangeDetectionStrategy, forwardRef, ContentChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ComponentBase, IComponentBase, applyMixins, ComponentMixins, PropertyCollectionInfo, FormBase, setValue } from '@syncfusion/ej2-angular-base';
import { RichTextEditor } from '@syncfusion/ej2-richtexteditor';
import { Template } from '@syncfusion/ej2-angular-base';


export const /** @type {?} */ inputs: string[] = ['autoSaveOnIdle','backgroundColor','bulletFormatList','cssClass','editorMode','enableAutoUrl','enableHtmlEncode','enableHtmlSanitizer','enablePersistence','enableResize','enableRtl','enableTabKey','enableXhtml','enabled','enterKey','fileManagerSettings','floatingToolbarOffset','fontColor','fontFamily','fontSize','format','formatter','height','htmlAttributes','iframeSettings','inlineMode','insertImageSettings','keyConfig','locale','maxLength','numberFormatList','pasteCleanupSettings','placeholder','quickToolbarSettings','readonly','saveInterval','shiftEnterKey','showCharCount','tableSettings','toolbarSettings','undoRedoSteps','undoRedoTimer','value','valueTemplate','width'];
export const /** @type {?} */ outputs: string[] = ['actionBegin','actionComplete','afterImageDelete','afterPasteCleanup','beforeDialogClose','beforeDialogOpen','beforeImageDrop','beforeImageUpload','beforePasteCleanup','beforeQuickToolbarOpen','beforeSanitizeHtml','blur','change','created','destroyed','dialogClose','dialogOpen','focus','imageRemoving','imageSelected','imageUploadFailed','imageUploadSuccess','imageUploading','quickToolbarClose','quickToolbarOpen','resizeStart','resizeStop','resizing','toolbarClick','toolbarStatusUpdate','updatedToolbarStatus','valueChange'];
export const /** @type {?} */ twoWays: string[] = ['value'];
/**
 * `ejs-richtexteditor` represents the Angular richtexteditor Component.
 * ```html
 * <ejs-richtexteditor></ejs-richtexteditor>
 * ```
 */
@ComponentMixins([ComponentBase, FormBase])
export class RichTextEditorComponent extends RichTextEditor implements IComponentBase {
public formCompContext : any;
public formContext : any;
public tagObjects: any;
	actionBegin: any;
	actionComplete: any;
	afterImageDelete: any;
	afterPasteCleanup: any;
	beforeDialogClose: any;
	beforeDialogOpen: any;
	beforeImageDrop: any;
	beforeImageUpload: any;
	beforePasteCleanup: any;
	beforeQuickToolbarOpen: any;
	beforeSanitizeHtml: any;
	blur: any;
	change: any;
	created: any;
	destroyed: any;
	dialogClose: any;
	dialogOpen: any;
	focus: any;
	imageRemoving: any;
	imageSelected: any;
	imageUploadFailed: any;
	imageUploadSuccess: any;
	imageUploading: any;
	quickToolbarClose: any;
	quickToolbarOpen: any;
	resizeStart: any;
	resizeStop: any;
	resizing: any;
	toolbarClick: any;
	toolbarStatusUpdate: any;
	updatedToolbarStatus: any;
public valueChange: any;
/**
 * Accepts the template design and assigns it as RichTextEditor’s content. 
 * The built-in template engine which provides options to compile template string into a executable function. 
 * For EX: We have expression evolution as like ES6 expression string literals
 * 
 * {% codeBlock src='rich-text-editor/value-template/index.md' %}{% endcodeBlock %}
 *     
 * \@default null
 */
@Template()
    public valueTemplate: any;
private skipFromEvent:boolean = true;
/**
 * @param {?} ngEle
 * @param {?} srenderer
 * @param {?} viewContainerRef
 * @param {?} injector
 */
constructor(private ngEle: ElementRef,
private srenderer: Renderer2,
private viewContainerRef:ViewContainerRef,
private injector: Injector) {
        super();
        this.element = this.ngEle.nativeElement;
        this.injectedModules = this.injectedModules || [];
        try {
                let mod = this.injector.get('RichTextEditorToolbar');
                if(this.injectedModules.indexOf(mod) === -1) {
                    this.injectedModules.push(mod)
                }
            } catch { }
        try {
                let mod = this.injector.get('RichTextEditorLink');
                if(this.injectedModules.indexOf(mod) === -1) {
                    this.injectedModules.push(mod)
                }
            } catch { }
        try {
                let mod = this.injector.get('RichTextEditorImage');
                if(this.injectedModules.indexOf(mod) === -1) {
                    this.injectedModules.push(mod)
                }
            } catch { }
        try {
                let mod = this.injector.get('RichTextEditorCount');
                if(this.injectedModules.indexOf(mod) === -1) {
                    this.injectedModules.push(mod)
                }
            } catch { }
        try {
                let mod = this.injector.get('RichTextEditorQuickToolbar');
                if(this.injectedModules.indexOf(mod) === -1) {
                    this.injectedModules.push(mod)
                }
            } catch { }
        try {
                let mod = this.injector.get('RichTextEditorHtmlEditor');
                if(this.injectedModules.indexOf(mod) === -1) {
                    this.injectedModules.push(mod)
                }
            } catch { }
        try {
                let mod = this.injector.get('RichTextEditorMarkdownEditor');
                if(this.injectedModules.indexOf(mod) === -1) {
                    this.injectedModules.push(mod)
                }
            } catch { }
        try {
                let mod = this.injector.get('RichTextEditorTable');
                if(this.injectedModules.indexOf(mod) === -1) {
                    this.injectedModules.push(mod)
                }
            } catch { }
        try {
                let mod = this.injector.get('RichTextEditorPasteCleanup');
                if(this.injectedModules.indexOf(mod) === -1) {
                    this.injectedModules.push(mod)
                }
            } catch { }
        try {
                let mod = this.injector.get('RichTextEditorResize');
                if(this.injectedModules.indexOf(mod) === -1) {
                    this.injectedModules.push(mod)
                }
            } catch { }
        try {
                let mod = this.injector.get('RichTextEditorFileManager');
                if(this.injectedModules.indexOf(mod) === -1) {
                    this.injectedModules.push(mod)
                }
            } catch { }

        this.registerEvents(outputs);
        this.addTwoWay.call(this, twoWays);
        setValue('currentInstance', this, this.viewContainerRef);
        this.formContext  = new FormBase();
        this.formCompContext  = new ComponentBase();
    }
/**
 * @param {?} registerFunction
 * @return {?}
 */
public registerOnChange(registerFunction: (_: any) => void): void {
    }
/**
 * @param {?} registerFunction
 * @return {?}
 */
public registerOnTouched(registerFunction: () => void): void {
    }
/**
 * @param {?} value
 * @return {?}
 */
public writeValue(value: any): void {
    }
/**
 * @param {?} disabled
 * @return {?}
 */
public setDisabledState(disabled: boolean): void {
    }
/**
 * @return {?}
 */
public ngOnInit() {
        this.formCompContext.ngOnInit(this);
    }
/**
 * @return {?}
 */
public ngAfterViewInit(): void {
        this.formContext.ngAfterViewInit(this);
    }
/**
 * @return {?}
 */
public ngOnDestroy(): void {
        this.formCompContext.ngOnDestroy(this);
    }
/**
 * @return {?}
 */
public ngAfterContentChecked(): void {
        
        this.formCompContext.ngAfterContentChecked(this);
    }
public registerEvents: (eventList: string[]) => void;
public addTwoWay: (propList: string[]) => void;
static decorators: DecoratorInvocation[] = [
{ type: Component, args: [{
    selector: 'ejs-richtexteditor',
    inputs: inputs,
    outputs: outputs,
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RichTextEditorComponent),
            multi: true
        }
    ],
    queries: {

    }
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: ElementRef, },
{type: Renderer2, },
{type: ViewContainerRef, },
{type: Injector, },
];
static propDecorators: {[key: string]: DecoratorInvocation[]} = {
'valueTemplate': [{ type: ContentChild, args: ['valueTemplate', ] },],
};
}

function RichTextEditorComponent_tsickle_Closure_declarations() {
/** @type {?} */
RichTextEditorComponent.decorators;
/**
 * @nocollapse
 * @type {?}
 */
RichTextEditorComponent.ctorParameters;
/** @type {?} */
RichTextEditorComponent.propDecorators;
/** @type {?} */
RichTextEditorComponent.prototype.formCompContext;
/** @type {?} */
RichTextEditorComponent.prototype.formContext;
/** @type {?} */
RichTextEditorComponent.prototype.tagObjects;
/** @type {?} */
RichTextEditorComponent.prototype.actionBegin;
/** @type {?} */
RichTextEditorComponent.prototype.actionComplete;
/** @type {?} */
RichTextEditorComponent.prototype.afterImageDelete;
/** @type {?} */
RichTextEditorComponent.prototype.afterPasteCleanup;
/** @type {?} */
RichTextEditorComponent.prototype.beforeDialogClose;
/** @type {?} */
RichTextEditorComponent.prototype.beforeDialogOpen;
/** @type {?} */
RichTextEditorComponent.prototype.beforeImageDrop;
/** @type {?} */
RichTextEditorComponent.prototype.beforeImageUpload;
/** @type {?} */
RichTextEditorComponent.prototype.beforePasteCleanup;
/** @type {?} */
RichTextEditorComponent.prototype.beforeQuickToolbarOpen;
/** @type {?} */
RichTextEditorComponent.prototype.beforeSanitizeHtml;
/** @type {?} */
RichTextEditorComponent.prototype.blur;
/** @type {?} */
RichTextEditorComponent.prototype.change;
/** @type {?} */
RichTextEditorComponent.prototype.created;
/** @type {?} */
RichTextEditorComponent.prototype.destroyed;
/** @type {?} */
RichTextEditorComponent.prototype.dialogClose;
/** @type {?} */
RichTextEditorComponent.prototype.dialogOpen;
/** @type {?} */
RichTextEditorComponent.prototype.focus;
/** @type {?} */
RichTextEditorComponent.prototype.imageRemoving;
/** @type {?} */
RichTextEditorComponent.prototype.imageSelected;
/** @type {?} */
RichTextEditorComponent.prototype.imageUploadFailed;
/** @type {?} */
RichTextEditorComponent.prototype.imageUploadSuccess;
/** @type {?} */
RichTextEditorComponent.prototype.imageUploading;
/** @type {?} */
RichTextEditorComponent.prototype.quickToolbarClose;
/** @type {?} */
RichTextEditorComponent.prototype.quickToolbarOpen;
/** @type {?} */
RichTextEditorComponent.prototype.resizeStart;
/** @type {?} */
RichTextEditorComponent.prototype.resizeStop;
/** @type {?} */
RichTextEditorComponent.prototype.resizing;
/** @type {?} */
RichTextEditorComponent.prototype.toolbarClick;
/** @type {?} */
RichTextEditorComponent.prototype.toolbarStatusUpdate;
/** @type {?} */
RichTextEditorComponent.prototype.updatedToolbarStatus;
/** @type {?} */
RichTextEditorComponent.prototype.valueChange;
/**
 * Accepts the template design and assigns it as RichTextEditor’s content. 
 * The built-in template engine which provides options to compile template string into a executable function. 
 * For EX: We have expression evolution as like ES6 expression string literals
 * 
 * {% codeBlock src='rich-text-editor/value-template/index.md' %}{% endcodeBlock %}
 *     
 * \@default null
 * @type {?}
 */
RichTextEditorComponent.prototype.valueTemplate;
/** @type {?} */
RichTextEditorComponent.prototype.skipFromEvent;
/** @type {?} */
RichTextEditorComponent.prototype.registerEvents;
/** @type {?} */
RichTextEditorComponent.prototype.addTwoWay;
/** @type {?} */
RichTextEditorComponent.prototype.ngEle;
/** @type {?} */
RichTextEditorComponent.prototype.srenderer;
/** @type {?} */
RichTextEditorComponent.prototype.viewContainerRef;
/** @type {?} */
RichTextEditorComponent.prototype.injector;
}



interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
