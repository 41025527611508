/**
 * Worksheet class
 * @private
 */
export class Worksheet {
    constructor() {
        this.isSummaryRowBelow = true;
        this.showGridLines = true;
        this.enableRtl = false;
    }
}
/**
 * Hyperlink class
 * @private
 */
export class HyperLink {
}
/**
 * Grouping class
 * @private
 */
export class Grouping {
}
/**
 * FreezePane class
 * @private
 */
export class FreezePane {
}
/**
 * MergeCell
 * @private
 */
export class MergeCell {
}
/**
 * MergeCells class
 * @private
 */
export class MergeCells extends Array {
    constructor() {
        super(...arguments);
        this.add = (mergeCell) => {
            let inserted = false;
            let count = 0;
            for (let mCell of this) {
                if (MergeCells.isIntersecting(mCell, mergeCell)) {
                    let intersectingCell = new MergeCell();
                    intersectingCell.x = Math.min(mCell.x, mergeCell.x);
                    intersectingCell.y = Math.min(mCell.Y, mergeCell.y);
                    intersectingCell.width = Math.max(mCell.Width + mCell.X, mergeCell.width + mergeCell.x);
                    intersectingCell.height = Math.max(mCell.Height + mCell.Y, mergeCell.height + mergeCell.y);
                    intersectingCell.ref = (this[count].ref.split(':')[0]) + ':' + (mergeCell.ref.split(':')[1]);
                    this[count] = intersectingCell;
                    mergeCell = intersectingCell;
                    inserted = true;
                }
                count++;
            }
            if (!inserted) {
                this.push(mergeCell);
            }
            return mergeCell;
        };
    }
    static isIntersecting(base, compare) {
        return (base.x <= compare.x + compare.width)
            && (compare.x <= base.x + base.width)
            && (base.y <= compare.y + compare.height)
            && (compare.y <= base.y + base.height);
    }
}
