var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Property, ChildProperty } from '@syncfusion/ej2-base';
/**
 * Specifies the Search settings of the File Manager.
 */
export class SearchSettings extends ChildProperty {
}
__decorate([
    Property(true)
], SearchSettings.prototype, "allowSearchOnTyping", void 0);
__decorate([
    Property('contains')
], SearchSettings.prototype, "filterType", void 0);
__decorate([
    Property(true)
], SearchSettings.prototype, "ignoreCase", void 0);
__decorate([
    Property(null)
], SearchSettings.prototype, "placeholder", void 0);
